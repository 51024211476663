import { Box, Container, Divider, Hidden } from '@material-ui/core';
import React from 'react';
import Img12Pay from 'src/images/campaign/merchant/atobaraisodanshitsu/12pay.png';
import Img3Pay from 'src/images/campaign/merchant/atobaraisodanshitsu/3pay.png';
import styles from './NPay.module.scss';

export default function NPay() {
  return (
    <section className={styles.section}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Hidden xsDown>
            <h1 className={styles.title}>
              3・6・12回あと払いが
              <br />
              分割手数料無料*
            </h1>
          </Hidden>
          <Hidden xsDown>
            <Box mt={8} />
          </Hidden>
          <Box className={styles.block}>
            <img
              width={460}
              height={360}
              alt="npay-1"
              src={Img12Pay}
              className={styles.banner}
            />
            <Box maxWidth={460}>
              <Hidden smUp>
                <h1 className={styles.title}>
                  3・6・12回あと払いが
                  <br />
                  分割手数料無料*
                </h1>
              </Hidden>
              <p className={styles.text}>
                お客様は、いま欲しいものを手元のお金を理由に諦めていませんか？
                <br /> <br />
                分割手数料無料*の3・6・12回あと払いの活用により、お客様は高額商品でもかしこく手に入れることができるため、売上アップが見込めます。
                <br /> <br />
                お客様のお支払いが3・6・12回に分かれても、加盟店様へは翌月に全額入金いたします。3・6・12回あと払いが利用できるペイディを導入することで、新たなお客様の獲得に繋がります。
              </p>
              <Box mt={8}></Box>
              <Divider />
              <Box mt={2}></Box>
              <p className={styles.note}>
                *口座振替・銀行振込のみ無料。コンビニ払いの場合、月一回支払い時に最大税込390円（すぐ払い利用時のみ支払いごと）に手数料が発生します。
                <br /> <br />
                ※3・6・12回あと払いの利用にはペイディアプリから本人確認が必要です。
                <br /> <br />
                ※6・12回あと払いの導入は、別途お申し込みが必要です。
              </p>
            </Box>
          </Box>
          <Hidden xsDown>
            <Box mt={20} />
          </Hidden>
          <Hidden smUp>
            <Box mt={11} />
          </Hidden>
          <Box className={styles.block}>
            <img
              width={460}
              height={360}
              alt="npay-2"
              src={Img3Pay}
              className={styles.banner}
            />
            <Box maxWidth={460}>
              <h2 className={styles.title}>
                分割した金額の自動表示で、
                <br />
                購入率と購入単価が大幅アップ。
              </h2>
              <Hidden xsDown>
                <Box mt={8} />
              </Hidden>
              <p className={styles.text}>
                タグを埋め込むだけで、３回に分割した金額を自動で表示することが可能。
                <br />
                <br />
                これまで一括価格が高く購入に至らなかったユーザーを獲得することで、売上の向上と購入単価の大幅アップが見込めます。
              </p>
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
