import React from 'react';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import TopBanner from 'src/components/Campaign/merchant/202403_plusshipping/TopBanner2';
import Campaign from 'src/components/Campaign/merchant/202403_plusshipping/Campaign2';
import Merits from 'src/components/Campaign/merchant/atobaraisodanshitsu/Merits';
import WhatIsPaidy from 'src/components/Campaign/merchant/atobaraisodanshitsu/WhatIsPaidy';
import NPay from 'src/components/Campaign/merchant/atobaraisodanshitsu/NPay';
import Application from 'src/components/Campaign/merchant/202403_plusshipping/Application';

const SEOProps = {
  title: SITE_METADATA.campaign_202403_plusshipping.title,
  description: SITE_METADATA.campaign_202403_plusshipping.description,
};

export default function Campaign202403Plusshipping() {
  return (
    <Layout hasNoHeader hasNoFooter SEOProps={SEOProps}>
      <TopBanner />
      <Campaign />
      <Merits />
      <WhatIsPaidy />
      <NPay />
      <Application />
    </Layout>
  );
}
