import { Box, Container, Hidden } from '@material-ui/core';
import React from 'react';
import Steps from 'src/components/Apple/Steps';
import StepsMobile from 'src/components/Apple/StepsMobile';
import ImgApplication0 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-0.png';
import ImgApplication1 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-1.png';
import ImgApplication2 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-2.png';
import ImgApplication3 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-3.png';
import styles from './Application.module.scss';

const DATA = [
  {
    image: ImgApplication0,
    title: 'PlusShipping経由での申込',
    info: 'ワンクリックでお申込みが完了します。情報の入力は必要ありません。',
  },
  {
    image: ImgApplication1,
    title: '加盟店審査',
    info: '追加情報が必要な場合がございます。',
  },
  {
    image: ImgApplication2,
    title: '審査結果をメールでご連絡',
    info: '1週間以内（目安）にご連絡いたします。',
  },
  {
    image: ImgApplication3,
    title: 'ご利用スタート',
    info: '初期設定が完了したらご利用開始となります。',
  },
];

export default function Application({
  data = DATA,
  columnWidth = 233,
  columnWidthMobile = 310,
  stepInfoCustomClassname = '',
}) {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Box display="flex" alignItems={'center'} flexDirection={'column'}>
          <Hidden xsDown>
            <Steps
              columnWidth={columnWidth}
              stepInfoCustomClassname={stepInfoCustomClassname}
              title={
                <>
                  登記簿や印鑑証明の提出不要。
                  <br />
                  お申し込みはウェブで完結、最短1週間でご利用開始。
                </>
              }
              steps={data}
            />
          </Hidden>
          <Hidden smUp>
            <StepsMobile
              textAlign="left"
              columnWidth={columnWidthMobile}
              title={
                <>
                  登記簿や印鑑証明の提出不要。
                  <br />
                  お申し込みはウェブで完結、<b>最短1週間</b>でご利用開始。
                </>
              }
              steps={data}
            />
          </Hidden>
          <p className={styles.text}>
            お申し込みや導入に関して、ご不明な点がございましたらお気軽にご連絡ください。お客さまの業種に合わせた専門の担当者がお答えします。
          </p>
        </Box>
      </Container>
      <div className={styles.footer} />
    </section>
  );
}
